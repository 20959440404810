import {useEffect, useState} from "react";
import "../periksa.css";
import Image from "../logofix_white.png";
import {auth, realtimedb} from "../Firebase";
import {ref, onValue, get, child} from "firebase/database";
import {Modal, useModal, Button, Text} from "@nextui-org/react";
import Back from "./back";
import {useUserAuth} from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Tutorial from "./Tutorial";

// import { Image } from "@nextui-org/react";

function Periksa() {
  const [data, setData] = useState();
  const refDb = ref(realtimedb);
  const [AL, setAl] = useState(0);
  const [perubahan, setPerubahan] = useState(0);
  const [status, setStatus] = useState(0);
  const [pesan, setPesan] = useState();
  const history = useNavigate()
  const {user} = useUserAuth();
  const { setVisible, bindings } = useModal();

  useEffect(() => {
    if (user?.uid) {
      get(child(refDb, user.uid))
        .then((res) => {
          if (res.exists()) setAl(res.val()["AL"]);
          if (res.exists()) setPerubahan(res.val()["ct"]);
          if (res.exists()) setStatus(res.val()["status"]);
          //if (res.exists()) setPesan(res.val()["pesan"]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    console.log("user", user);
  }, [user]);

  return (
    <body>
      <div class="periksa">
        <Back />

        <div class="periksaimg">
          <img src={Image} class="prkimage" alt="logo_glassist" />
        </div>

        <div class="periksa-cek">
          <form action="#" class="periksa-form">
            <div class="top">
              <h1 class="title">PERIKSA RETINOPATI DIABETIK</h1>
              <div class="periksabtn">
                <button onClick={() => history("/tutorial")}>Cara Penggunaan</button>
              </div>

              { /*            <div>
      <Button auto flat onClick={() => setVisible(true)}
      css={{
        color:"white",
        backgroundColor:"#9d6cae",
        width:"350px",
        height: "52px",
        borderRadius:"49px",
        fontWeight:"700",
        marginLeft:"1rem"
      }}>
        Cara Penggunaan
      </Button>
      <Modal
        scroll
        fullScreen
        closeButton
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...bindings}
      >
        <Modal.Header>
          <Text id="modal-title" size={35}
          css={{
            color:"#9d6cae"
          }}>
          Cara Penggunaan Alat
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text id="modal-description">
          <Tutorial />
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setVisible(false)}>Selesai</Button>
        </Modal.Footer>
      </Modal>
        </div>*/}
            </div>

            <div class="bottom">
            <h3>Axial Length (mm)</h3>
              <div class="skill">
                <div class="outer">
                  <div class="inner">
                    {/* <div id="number">{Math.round(volume / 100) / 100}</div> */}
                    <div id="number">{AL}</div>
                  </div>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="160px"
                  height="160px"
                >
                  <defs>
                    <linearGradient id="GradientColor">
                      <stop offset="0%" stop-color="#745ea9" />
                      <stop offset="100%" stop-color="#9d6cae" />
                    </linearGradient>
                  </defs>
                  <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                </svg>

                {/*<Card>
                  <Text>{volume}</Text>
                </Card>*/}

                {/*<Grid.Container gap={2}>
                  {data.map((item=>
                    <Card>
                      <Card.Header>
                        <Text b>ID #{item.alias}</Text>
                      </Card.Header>
                      <Card.Divider />
                      <Card.Body css={{ py: "$10" }}>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>
                          Tanggal: {item.day}/{item.month}/{item.year} {item.c_time}
                        </Text>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>Y: {item.Y}</Text>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>X: {item.X}</Text>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>A: {item.A}</Text>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>Volume Okular: {item.volume}</Text>
                        <Text css={{ paddingLeft: 10, paddingRight: 50, paddingBottom: 10 }}>status: {item.status}</Text>
                      </Card.Body>
                      <Card.Divider />
                      <Card.Footer>
                        <Row justify="flex-end" css={{ paddingRight: 2, paddingBottom: 15 }}>
                          <Link href="/">Hasil Pemeriksaan</Link>
                        </Row>
                      </Card.Footer>
                    </Card>
                  ))}
                  </Grid.Container> */}
              </div>

              {<div>
      <Button className="status" auto shadow color="secondary" onClick={() => setVisible(true)}
      css={{
        backgroundColor:"#745ea9",
        //marginLeft:"20%",
        marginTop: "2rem"
      }}>
       Status : {status}
      </Button>
      <Modal
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...bindings}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Axial Length : <b>{AL}</b>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text id="modal-description">
            {pesan}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button auto onClick={() => setVisible(false)}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
  </div>}
            </div>
          </form>
        </div>
      </div>
    </body>
  );
}

export default Periksa;